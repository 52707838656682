<template>
  <v-container grid-list-xs>
    <v-layout row wrap>
      <v-flex xs12 sm6>
        <iframe
          allow="microphone;"
          width="100%"
          height="500"
          src="https://console.dialogflow.com/api-client/demo/embedded/28f488cc-88e3-4fad-82c8-ae8c927cfd7d"
        >
        </iframe>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>
